import { LoginType, MsalAuthProvider } from "react-aad-msal";

const config = {
  auth: {
    authority: process.env.REACT_APP_Authority,
    clientId: process.env.REACT_APP_Authentication_Key,
    redirectUri: process.env.REACT_APP_Redirect_URL,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: ["user.read"],
};

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + "/auth.html",
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);
