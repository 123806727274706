import { Box,Button } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MultiChart from "../../components/MultiChart";
import { useNavigate } from "react-router-dom";

const GetDetailsURL = process.env.REACT_APP_Base_Url+'GetGraph';

const Multi = () => {
  const [data, setClusterDetails] = useState([]);
  const location = useLocation();
  const { cluster, division, liquid, pack_type, zone, state,price_segement } = location.state; 
  const navigate = useNavigate();
  const handleBack = () => {
      navigate(`/clusterScreen/${cluster || ""}`);
  };

  useEffect(() => {
    fetchData()
      .then(data => setClusterDetails(data))
      .catch(error => console.error('Error:', error));
  }, []);


  const fetchData = async () => {
    try {
      const response = await axios.post(GetDetailsURL, {
        cluster,
        division,
        "Liq": liquid,
        pack_type,
        state,
        zone,
        price_segement

      })
      console.log("Response : ", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
    <Box m="10px 10px" style={{display: 'flex', justifyContent: 'end'}}>
      <Button
        variant="contained"
        size="lg"
        onClick={handleBack}
        color={"warning"}
        style={{
          border: "none",
          borderRadius: "5px",
          width: "120px",
          height: "40px",
        }}
      >
        Back
      </Button>
    </Box>
      
    <Box height="75vh" m="10px 10px">
      {data.length > 0 && <MultiChart data={data} />}
    </Box>
  </>
  );
};

export default Multi;
