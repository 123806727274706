import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, Snackbar, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { utils, writeFileXLSX } from "xlsx";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import "./index.css";

const GetDepot_URL = process.env.REACT_APP_Base_Url + "GetDepotLevel";
const UPDATE_URL = process.env.REACT_APP_Base_Url + "UpdateDepotData";
const GetDepoLevelExport_URL =
  process.env.REACT_APP_Base_Url + "GetDepotLevelExport";
const GetDepoLevelExportLink_URL =
  process.env.REACT_APP_Base_Url + "GetDepotLevelExportLink";
const options = { maximumFractionDigits: 2 };
const DepotScreen = () => {
  console.log(GetDepot_URL);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    size: 10,
  });
  const [rowCountState, setRowCountState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [totalABP, setTotalABP] = useState(0);
  const [totalP0, setTotalP0] = useState(0);
  const [totalP2_Initial, setTotalP2_Initial] = useState(0);
  const [totalP2_Final, setTotalP2_Final] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);

  let requestData = {};
  let cluster = null;

  if (location.state !== null) {
    requestData = { Liq: location.state.liquid, ...location.state };
    cluster = location.state.cluster;
  }

  useEffect(() => {
    fetchData();
    export_DepotData_CSV();
  }, [paginationModel]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(GetDepot_URL, {
        page: paginationModel.page,
        size: paginationModel.size,
        UserName: sessionStorage.getItem("username"),
        ...requestData,
      });

      const { depotList } = response.data;
      const { totalRecords } = response.data;
      const { totalABP, totalP0, totalP2_Initial, totalP2_Final } =
        response.data;
      setRows(depotList);
      setRowCountState(totalRecords);
      setTotalABP(totalABP);
      setTotalP0(totalP0);
      setTotalP2_Initial(totalP2_Initial);
      setTotalP2_Final(totalP2_Final);
      // Assuming the server provides the actual row count
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const export_DepotData_CSV = async () => {
    try {
      setDisabled(true);
      setLoading(true);
      const export_response = await axios.post(GetDepoLevelExportLink_URL, {
        UserName: sessionStorage.getItem("username"),
      });

      const actualData = export_response?.data;

      // const csvData = [
      //   [
      //     "Year",
      //     "Month",
      //     "Week",
      //     "Zone",
      //     "Division",
      //     "Cluster",
      //     "State",
      //     "Market",
      //     "Depot",
      //     "Price_Segment",
      //     "Product_Segment",
      //     "Product",
      //     "Pack Type",
      //     "Channel",
      //     "Sub_Channel",
      //     "Train_Test_Forecast",
      //     "Abp",
      //     "Wmape",
      //     "Oos1",
      //     "Dsr",
      //     "Sales_Loss",
      //     "Month_Name",
      //     "Date_V2",
      //     "Key",
      //     "P0",
      //     "Liquid",
      //     "P2",
      //   ],
      //   ...actualData.map(
      //     ({
      //       year,
      //       month,
      //       week,
      //       zone,
      //       division,
      //       cluster,
      //       state,
      //       market,
      //       depot,
      //       price_segment,
      //       product_Segment,
      //       product,
      //       pack_type,
      //       channel,
      //       sub_Channel,
      //       train_Test_Forecast,
      //       abp,
      //       wmape,
      //       oos1,
      //       dsr,
      //       sales_Loss,
      //       month_Name,
      //       date_v2,
      //       key,
      //       p0,
      //       liquid,
      //       p2,
      //     }) => [
      //       year,
      //       month,
      //       week,
      //       zone,
      //       division,
      //       cluster,
      //       state,
      //       market,
      //       depot,
      //       price_segment,
      //       product_Segment,
      //       product,
      //       pack_type,
      //       channel,
      //       sub_Channel,
      //       train_Test_Forecast,
      //       abp,
      //       wmape,
      //       oos1,
      //       dsr,
      //       sales_Loss,
      //       month_Name,
      //       date_v2,
      //       key,
      //       p0,
      //       liquid,
      //       p2,
      //     ]
      //   ),
      // ];

      setExportData(actualData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };
  const handleCellEditCommit = (params) => {
    // const { id, field, value } = params;
    const value = parseInt(params.value);
    const updatedRows = rows.map((row) =>
      row.depoID === params.id
        ? { ...row, [params.field]: value, is_Updated: "Y" }
        : row
    );
    setRows(updatedRows);
    // Enable save button when a cell in P2 volume is edited.
    setIsSaveButtonDisabled(false);
  };

  const handleSave = async () => {
    try {
      setIsLoading(true);
      const editedRows = rows.filter((row) => row.is_Updated === "Y");

      let response = await axios.patch(UPDATE_URL, editedRows);
      response = response.data;
      if (response === "Depot Updated Successfully") {
        setIsToasterOpen(true);
      }
      fetchData();
      setIsSaveButtonDisabled(true);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    const sure = window.confirm(
      "Please ensure you have saved the changes.\n Are you sure you need to go back."
    );
    if (sure) {
      navigate(`/clusterScreen/${cluster || ""}`);
    } else {
    }
  };

  const columns = [
    {
      field: "zone",
      headerName: "Zone",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "division",
      headerName: "Division",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "cluster",
      headerName: "Cluster",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "depot",
      headerName: "Depot",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "liquid",
      headerName: "Liquid",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "pack_type",
      headerName: "Pack Type",
      headerAlign: "center",
      align: "center",
      width: 110,
    },
    {
      field: "price_segment",
      headerName: "Price Segment",
      headerAlign: "center",
      align: "center",
      width: 110,
    },
    {
      field: "abp",
      headerName: "ABP Volume",
      headerAlign: "center",
      align: "center",
      width: 110,
      type: "number",
    },
    {
      field: "p0",
      headerName: "P0 Volume",
      headerAlign: "center",
      align: "center",
      width: 110,
    },
    {
      field: "p2_Initial",
      headerName: "P2 Initial",
      headerAlign: "center",
      align: "center",
      width: 110,
    },
    {
      field: "p2_Final",
      headerName: "P2 Final",
      headerAlign: "center",
      align: "center",
      editable: true,
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            color: "black",
            border: "1px black solid",
            backgroundColor: "white",
            width: "150px",
            height: "48px",
            padding: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {params.value}
        </div>
      ),
    },
  ];
  const columnGroupingModel = [
    {
      groupId: "Total ABP Volume",
      headerName: "ABP Volume",
      description: "",
      headerAlign: "center",
      children: [
        {
          //Intl.NumberFormat("en-US",options).format(totalABP)
          groupId: "ABP Volume",
          headerName: `${Intl.NumberFormat("en-US", options).format(totalABP)}`,
          children: [{ field: "abp" }],
          headerAlign: "center",
        },
      ],
    },
    {
      groupId: "Total P0",
      headerName: "P0 Volume",
      description: "",
      children: [
        {
          groupId: "P0 Volume",
          headerName: `${Intl.NumberFormat("en-US", options).format(totalP0)}`,
          children: [{ field: "p0" }],
          headerAlign: "center",
        },
      ],
      headerAlign: "center",
    },
    {
      groupId: "Total P2 Initial",
      headerName: "Total P2 Initial",
      description: "",
      children: [
        {
          groupId: "P2 Initial",
          headerName: `${Intl.NumberFormat("en-US", options).format(
            totalP2_Initial
          )}`,
          children: [{ field: "p2_Initial" }],
          headerAlign: "center",
        },
      ],
      headerAlign: "center",
    },
    {
      groupId: "Total P2 Final",
      headerName: "P2 Final",
      description: "",
      headerAlign: "center",
      children: [
        {
          groupId: "P2 Final",
          headerName: `${Intl.NumberFormat("en-US", options).format(
            totalP2_Final
          )}`,
          children: [{ field: "p2_Final" }],
          headerAlign: "center",
        },
      ],
    },
  ];
  const handleToasterClose = () => {
    setIsToasterOpen(false);
    setIsSaveButtonDisabled(true);
  };

  const exportToExcel = () => {
    setDisabled(true);
    setLoading(true);
    const a = document.createElement("a");
    a.href = exportData;
    a.download = exportData;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDisabled(false);
    setLoading(false);
  };

  return (
    <>
      <Box m="10px">
        <Box
          justifyContent="space-between"
          sx={{
            // marginTop: "1rem",
            textAlign: "right",
            display: "flex",
            flexDirection: "row",
            marginBottom: "-40px",
          }}
        >
          <Header title="Depot Level Input" />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              variant="contained"
              size="lg"
              color={"secondary"}
              onClick={exportToExcel}
              loading={loading}
              style={{
                padding: "0.5rem 1rem",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "120px",
                height: "40px",
                color: "white",
              }}
              disabled={disabled}
            >
              {" "}
              Export{" "}
            </LoadingButton>

            <Button
              variant="contained"
              size="lg"
              onClick={handleBack}
              color={"warning"}
              style={{
                marginLeft: "10px",
                padding: "0.5rem 1rem",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "120px",
                height: "40px",
              }}
            >
              Back
            </Button>
          </div>
        </Box>
        <Box
          style={{ height: "75vh", width: "100%", overflow: "auto" }}
          m="20px 0 0 0"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[300],
              borderBottom: "none",
              color: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[300],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
            "& .MuiTablePagination-root": {
              color: "white",
            },
          }}
        >
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isToasterOpen}
            autoHideDuration={3000}
            onClose={handleToasterClose}
          >
            <Alert onClose={handleToasterClose} severity="success">
              Depot Update Successfully
            </Alert>
          </Snackbar>
          <div style={{ height: "75vh", width: "100%" }}>
            <DataGrid
              rows={rows}
              getRowId={(row) => row.depoID}
              columns={columns}
              loading={isLoading}
              rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
              pagination
              pageSize={paginationModel.size}
              paginationMode="server"
              paginationModel={paginationModel}
              onPaginationModelChange={paginationModel}
              rowCount={rowCountState}
              onPageChange={(newPage) => {
                setPaginationModel({ ...paginationModel, page: newPage + 1 });
              }}
              onPageSizeChange={(newPageSize) =>
                setPaginationModel({ ...paginationModel, size: newPageSize })
              }
              experimentalFeatures={{ columnGrouping: true }}
              onCellEditCommit={handleCellEditCommit}
              columnGroupingModel={columnGroupingModel}
              hideFooterSelectedRowCount={true}
              density="compact"
              onCellEditStart={() => {
                if (!isSaveButtonDisabled) {
                  setIsSaveButtonDisabled(true);
                }
              }}
            />
          </div>
        </Box>
        <Box
          justifyContent={"space-between"}
          sx={{ marginTop: "10px", textAlign: "right" }}
        >
          {cluster && (
            <Button
              variant="contained"
              size="lg"
              onClick={handleSave}
              disabled={isSaveButtonDisabled}
              color={"success"}
              style={{
                padding: "0.5rem 1rem",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                width: "120px",
                marginBottom: "10px",
              }}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DepotScreen;
