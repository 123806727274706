import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Snackbar,
  Stack,
  TextField,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";

import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../theme";
import "./index.css";

const SERVER_URL = process.env.REACT_APP_Base_Url + "GetClusterData";
const UPDATE_URL = process.env.REACT_APP_Base_Url + "UpdateClusterData";
const DROPDOWN_CLUSTER_URL = process.env.REACT_APP_Base_Url + "UserClusterData";

export default function ServerPaginationGrid({logout}) {
  const theme = useTheme();
  const storeEditableRowId = useRef(null);
  const storeEditableRowOldValue = useRef(null);
  const colors = tokens(theme.palette.mode);
  const options = {  maximumFractionDigits: 2   }
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const [paginationModel, setPaginationModel] = useState({
    page: 1,
    size: 100,
  });

  const [rowCountState, setRowCountState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const { cluster } = useParams();
  const [totalABP, setTotalABP] = useState(0);
  const [totalP0, setTotalP0] = useState(0);
  const [totalP2_Initial, setTotalP2_Initial] = useState(0);
  const [totalP2_Final, setTotalP2_Final] = useState(0);

  const [selectedRowId, setSelectedRowId] = useState(null);
  const [clusterData, setClusterData] = useState({
    cluster: cluster || "",
    clusterId: 0,
  });
  const [clusterDataOptions, setClusterDataOptions] = useState([]);
  const [clusterDropdown, setClusterDropdown] = useState([]);
  const [clusterSelectedDropdown, setClusterSelectedDropdown] = useState("");

  //dropdown
  const [selectedCluster, setSelectedCluster] = useState("");
  const [isToasterOpen, setIsToasterOpen] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSelectedCluster(event.target.value);
  };

  const fetchClusterData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(DROPDOWN_CLUSTER_URL, {
        UserName: sessionStorage.getItem("username"),
      });
      const dataForDropdown = response?.data?.map((value) => {
        return {
          cluster: value.cluster,
          clusterId: value.clusterId,
        };
      });
      setClusterDataOptions(dataForDropdown);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(cluster);

    fetchClusterData();

    setIsSaveButtonDisabled(true);
  }, [paginationModel]);

  const changeColumnSelection = (data) => {
    setClusterData(data || { cluster: "", clusterId: 0 });
    const navigateUrl = data?.cluster || "";
    navigate(`/clusterScreen/${navigateUrl}`);
    fetchData(data?.cluster);
  };

  useEffect(() => {
    fetchData(cluster);
  }, []);

  const fetchData = async (cluster = "") => {
    try {
      setIsLoading(true);
      const response = await axios.post(SERVER_URL, {
        page: paginationModel.page,
        size: paginationModel.size,
        UserName: sessionStorage.getItem("username"),
        cluster: cluster,
      });

      const { clusterList } = response.data;
      const { totalRecords } = response.data;
      const { totalABP, totalP0, totalP2_Intail, totalP2_Final } = response.data;
      setRows(clusterList);
      console.log("totalRecords", totalRecords);
      setRowCountState(totalRecords); // Assuming the server provides the actual row count
      setTotalABP(totalABP);
      setTotalP0(totalP0);
      setTotalP2_Initial(totalP2_Intail);
      setTotalP2_Final(totalP2_Final);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const styles = {
    redHeader: {
      color: "red",
    },
  };

  const columns = [
    {
      field: "zone",
      headerName: "Zone",
      type: "string",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "division",
      headerName: "Division",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "cluster",
      headerName: "Cluster     ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "state",
      headerName: "State",
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "liquid",
      headerName: "Liquid",
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "pack_type",
      headerName: "Pack Type",
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => {
        const passData = {
          zone: params.row.zone,
          division: params.row.division,
          cluster: params.row.cluster,
          state: params.row.state,
          liquid: params.row.liquid,
          pack_type: params.row.pack_type,
          price_segement: params.row.price_segment,
        };
        return (
          <Link
            style={{ color: "blue", cursor: "pointer" }}
            variant="outline"
            size="small"
            onClick={() =>
              navigate(`/MultiChart`, {
                state: passData,
              })
            }
          >
            {params.row.pack_type}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      field: "price_segment",
      headerName: "Price Segment",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "abp",
      headerName: "ABP Volume",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "p0",
      headerName: "P0 Volume",
      headerAlign: "center",
      align: "center",
      width: 80,
    },
    {
      field: "p2_Initial_Input",
      headerName: "P2 Initial",
      headerAlign: "center",
      align: "center",
      editable: true,
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            color: "black",
            border: "1px black solid",
            backgroundColor: "white",
            width: "150px",
            height: "48px",
            padding: "14px",
            textAlign: "center",
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "p2_Final_Volume",
      headerName: "P2 Final",
      headerAlign: "center",
      align: "center",
      width: 110,
      renderCell: (params) => (
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          {params.value}
        </div>
      ),
      
    },

    {
      field: "comments",
      headerName: "Comments",
      headerAlign: "center",
      align: "center",
      editable: false,
      width: 150,
    },
    {
      field: "test",
      headerName: "Depot Link",
      headerAlign: "center",
      align: "center",
      width: 80,

      renderCell: (params) => {
        const passData = {
          zone: params.row.zone,
          division: params.row.division,
          cluster: params.row.cluster,
          state: params.row.state,
          liquid: params.row.liquid,
          pack_type: params.row.pack_type,
          price_segement: params.row.price_segment,
        };
        return (
          <Link
            style={{ color: "blue", cursor: "pointer" }}
            variant="outline"
            size="small"
            onClick={() =>
              navigate(`/depotScreen/${params.row.cluster}`, {
                state: passData,
              })
            }
          >
            NEXT
          </Link>
        );
      },
      width: 150,
    },
  ];

  const handleCellEditCommit = (params) => {
    const value = parseFloat(params.value);
    console.log("value", value);

    if (
      params.field === "p2_Initial_Input" &&
      storeEditableRowOldValue.current !== value
    ) {
      storeEditableRowId.current = params?.id || null;
      console.log("storeEditableRowId.current", storeEditableRowId.current);
      const p0Value = params.row ? parseFloat(params.row.p0) : 0;
      console.log("p0 inside handle cell", p0Value);

      if (
        isNaN(value) ||
        isNaN(p0Value) ||
        Math.abs(value - p0Value) > p0Value * 0.1
      ) {
        console.log("open comment");
        setCommentDialogOpen(true);
        setSelectedRowId(params.id);

        const updatedRows = rows.map((row) =>
          row.clusterId === params.id
            ? { ...row, [params.field]: value, is_Updated: "Y" }
            : row
        );
        setRows(updatedRows);
        return;
      }
      const updatedRows = rows.map((row) =>
        row.clusterId === params.id
          ? { ...row, [params.field]: value, is_Updated: "Y" }
          : row
      );
      setRows(updatedRows);
      // Enable save button when a cell in P2 volume is edited.
      setIsSaveButtonDisabled(false);
    }
  };

  const handleSave = async (inputArray) => {
    try {
      // setIsLoading(true);

      // Filter edited rows
      const editedRows = inputArray.filter((row) => row.is_Updated === "Y");
      console.log("edit,edited editedRows", editedRows);

      setIsSaveButtonDisabled(true);
      setIsLoading(true);

      // Perform patch request to update edited rows
      let response = await axios.patch(UPDATE_URL, editedRows);
      response = response.data;

      if (response === "Cluster Updated Successfully") {
        // alert(response);
        setIsToasterOpen(true);
        setIsSaveButtonDisabled(true);
        setIsLoading(false);
      }
      console.log();
      // Fetch fresh data after updating
      fetchData(clusterData.cluster);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCommentDialogClose = () => {
    setCommentDialogOpen(false);
    setSelectedRowId(null);
    setComment("");
  };
  const handleSaveComment = (params) => {
    if (comment.length >= 1) {
      const value = parseInt(params.value);

      const updatedRows = rows.map((row) =>
        row.clusterId === storeEditableRowId.current
          ? { ...row, comments: comment, is_Updated: "Y" }
          : row
      );
      setRows(updatedRows);
      handleCommentDialogClose();
      handleSave(updatedRows);
    }
  };

  const handleToasterClose = () => {
    setIsToasterOpen(false);
    setIsSaveButtonDisabled(true);
  };

  const handleCancleClick = () => {
    const updatedRows = rows.map((row) =>
      row.clusterId === storeEditableRowId.current
        ? { ...row, p2_Initial_Input: storeEditableRowOldValue.current }
        : row
    );
    setRows(updatedRows);

    handleCommentDialogClose();
  };

  const onProcessRowUpdate = (params) => {
    const p2Value = parseFloat(params.p2_Initial_Input);

    if (!isNaN(p2Value) && parseInt(storeEditableRowOldValue.current) !== p2Value) {
      storeEditableRowId.current = params?.clusterId || null;
      const removeComma = params.p0.replace(',', '');
      const p0Value = parseFloat(removeComma);
      const p0_ten_percent_value = p0Value * 0.1;
      const plus_value = p0Value + p0_ten_percent_value;
      const minus_value = p0Value - p0_ten_percent_value;

      const calc_percent = p2Value > plus_value || p2Value < minus_value;

      if (calc_percent) {
        setCommentDialogOpen(true);
      } else {
        // Enable save button when a cell in P2 volume is edited.
        setIsSaveButtonDisabled(false);
      }
      const updatedRows = rows.map((row) =>
        row.clusterId === params.clusterId
          ? { ...row, p2_Initial_Input: p2Value, is_Updated: "Y" }
          : row
      );
      setRows(updatedRows);

      return { ...params };
    }
    return { ...params };
  };

  const columnGroupingModel = [
   {
    groupId: 'Total ABP Volume',
    headerName: 'ABP Volume',
    description: '',
    headerAlign: 'center',
    children: [
        {
          groupId: 'ABP Volume',
          headerName: `${Intl.NumberFormat("en-US",options).format(totalABP)}`,
          children: [{ field: 'abp' }],
          headerAlign: 'center',
        },
      ],
    },
    {
    groupId: 'Total P0',
    headerName: 'Total P0',
    description: '',
    children: [
        {
          groupId: "P0 Volume",
          headerName: `${Intl.NumberFormat("en-US",options).format(totalP0)}`,
          children: [{ field: 'p0' }],
          headerAlign: 'center',
        },
    ],
    headerAlign: 'center',
    },
    {
    groupId: 'Total P2 Initial',
    headerName: 'Total P2 Initial',
    description: '',
    children: [
      {
        groupId: 'P2 Initial',
        headerName: `${Intl.NumberFormat("en-US",options).format(totalP2_Initial)}`,
        children: [{ field: 'p2_Initial_Input' }],
        headerAlign: 'center',
      },
    ],
    headerAlign: 'center',
    },
    {
    groupId: 'Total P2 Final',
    headerName: 'P2 Final',
    description: '',
    headerAlign: 'center',
    children: [
        {
          groupId: 'P2 Final',
          headerName: `${Intl.NumberFormat("en-US",options).format(totalP2_Final)}`,
          children: [{ field: 'p2_Final_Volume' }],
          headerAlign: 'center',
        },
      ],
    },
  ];

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isToasterOpen}
        autoHideDuration={5000}
        onClose={handleToasterClose}
      >
        <Alert onClose={handleToasterClose} severity="success">
          Cluster Update Successfully
        </Alert>
      </Snackbar>
      <Box m="0px 0px 0px 10px">
        <Header title="Cluster Level Input" />
      </Box>
      <Stack
        direction={"row"}
        margin="-20px 0px 0px 0px"
        spacing={1}
        justifyContent="space-between"
        padding="0px 10px"
        height="50px"
      >
        <Autocomplete
          limitTags={1}
          onChange={(_, data) => changeColumnSelection(data)}
          value={clusterData || []}
          defaultValue={{ clusterId: 0, cluster: "" }}
          id="cluster_dropdown"
          // Multiple
          sx={{ width: "280px" }}
          isOptionEqualToValue={(option, value) =>
            option.cluster === value.cluster
          }
          size="small"
          options={clusterDataOptions || []} // filterData
          getOptionLabel={(option) => (option ? option.cluster : {})}
          renderInput={(params) => (
            <TextField {...params} size="small" placeholder="Cluster list"/>
          )}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.cluster}>
                {option ? option.cluster : ""}
              </Box>
            );
          }}
        />

        <Button onClick={() => {
            logout();
            sessionStorage.removeItem('username');
          }} 
          variant="contained" 
          color="primary"
          style={{
            backgroundColor:'red',
            color: '#fff!important',
            display: 'flex!important',
            marginLeft: 'auto!important',
            marginTop: '2%!important',
            fontSize: '15px',
            height: '40px'
          }}
          >Log Out</Button>
      </Stack>
      <Box
        style={{ height: "75vh", width: "100%", overflow: "auto", padding: '0px 10px' }}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[300],
            borderBottom: "none",
            color: 'white'
          },
          /* '.MuiDataGrid-columnSeparator': {
            color: 'black',
          }, */
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[300],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .MuiTablePagination-root": {
            color: 'white',
          }
        }}
      >
        <DataGrid
          rows={rows}
          getRowId={(row) => row.clusterId}
          columns={columns}
          loading={isLoading}
          hideFooterPagination={isLoading}
          pageSize={paginationModel.size}
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={paginationModel}
          rowCount={rowCountState}
          onPageChange={(newPage) => {
            console.log("onPageChange newPage", newPage);
            setPaginationModel({ ...paginationModel, page: newPage + 1 });
          }}
          onPageSizeChange={(newPageSize) =>
            setPaginationModel({ ...paginationModel, size: newPageSize })
          }
          experimentalFeatures={{ newEditingApi: true, columnGrouping: true }}
          onCellEditStart={(params) => {
            storeEditableRowOldValue.current = params?.value;
            if (!isSaveButtonDisabled) {
              setIsSaveButtonDisabled(true);
            }
          }}
          processRowUpdate={onProcessRowUpdate}
          onProcessRowUpdateError={(error) => console.error(error)}
          columnGroupingModel={columnGroupingModel}
          hideFooterSelectedRowCount={true}
          density="compact"
        />
      </Box>

      <div style={{ marginTop: "10px", textAlign: "right", padding: '0px 10px' }}>
        <Button
          variant="contained"
          onClick={() => handleSave(rows)}
          disabled={isSaveButtonDisabled}
          color={"success"}
          style={{
            padding: "0.5rem 1rem",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginBottom: "10px",
            fontSize: '15px',
            width: "90px"
          }}
        >
          Save
        </Button>
      </div>
      <Dialog open={commentDialogOpen} onClose={handleCommentDialogClose}>
        <DialogTitle>Comment Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The entered P2 value is more than + or - 10% of P0. Please enter a
            comment to proceed.
          </DialogContentText>
          <TextField
            label="Comments"
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancleClick}>Cancel</Button>
          <Button onClick={handleSaveComment}>Save Comment</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
