import React from "react";
import ReactApexChart from "react-apexcharts";

function MultiChart({ data }) {
  const [chartData, setChartData] = React.useState({
    series: [
      {
        name: "P0",
        type: "column",
        data: data.map((item) => item.p0_Volume),
      },
      {
        name: "P2",
        type: "column",
        data: data.map((item) => item.p2_Volume),
      },
      {
        name: "ABP",
        type: "line",
        data: data.map((item) => item.abp),
      },
    ],
    options: {
      chart: {
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        height: 400,
        type: "line",
        stacked: false,
        zoom: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [1, 1, 4],
      },
      xaxis: {
        categories: data.map((item) => item.month),
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#000408",
        },
        labels: {
          style: {
            colors: "#000408",
          },
        },
        title: {
          text: "Months",
          style: {
            color: "#000408",
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#000408",
          },
          labels: {
            style: {
              colors: "#000408",
            },
          },
          title: {
            text: "Volume",
            style: {
              color: "#000408",
            },
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  });
  return (
    <div id="charts">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={400}
      />
    </div>
  );
}
export default MultiChart;
