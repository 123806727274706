import { Box,Button, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import Athena from '../../MetaData/Athena.png';
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

const Topbar = (logOut) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={5.8}
      sx={{
        backgroundImage: `url(${Athena})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
      </Box>
      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
         <div className='show-hide-button'>
          {/* <label className='userName-text'
          style={{
              margin: '0px 15px 0px 0px',
              fontSize: 'large'
          }}
          >{sessionStorage.getItem('username')}</label> */}
          {/* <Button onClick={() => {
            logOut.logOut();
            sessionStorage.removeItem('username');
          }} 
          variant="contained" 
          color="secondary"
          style={{
            backgroundColor:'red',
            color: '#fff!important',
            display: 'flex!important',
            marginLeft: 'auto!important',
            marginTop: '2%!important'
          }}
          >Log Out</Button> */}
        </div>

      </Box>
    </Box>
  );
};

export default Topbar;
