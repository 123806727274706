import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Topbar from "./screens/global/Topbar";
import Login from "./screens/Login";
import Sidebar from "./screens/global/Sidebar";
import DepotScreen from "./screens/depotScreen";
import ClusterScreen from "./screens/clusterScreen";
import Multi from "./screens/multichart";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./authProvider";
// import Multi from "./components/Multi";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <>
      <AzureAD provider={authProvider}>
        {({ login, logout, authenticationState, error, accountInfo }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              sessionStorage.setItem("username", accountInfo.account.userName);
              return (
                <ColorModeContext.Provider value={colorMode}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                      <Sidebar isSidebar={isSidebar} />
                      <main className="content" style={{overflowY: 'auto'}}>
                        <Topbar setIsSidebar={setIsSidebar} />
                        <Routes>
                          <Route path="/" element={<ClusterScreen logout={logout} />} />
                          <Route
                            path="/clusterScreen/"
                            element={<ClusterScreen logout={logout} />}
                          />
                          <Route
                            path="/clusterScreen/:cluster"
                            element={<ClusterScreen logout={logout} />}
                          />
                          <Route
                            path="/depotScreen/"
                            element={<DepotScreen />}
                          />
                          <Route path="/MultiChart" element={<Multi />} />{" "}
                          <Route
                            path="/depotScreen/:cluster"
                            element={<DepotScreen />}
                          />
                        </Routes>
                      </main>
                    </div>
                  </ThemeProvider>
                </ColorModeContext.Provider>
              );
            case AuthenticationState.Unauthenticated:
              return <Login login={login} />;
            case AuthenticationState.InProgress:
              return <p>Authenticating...</p>;
          }
        }}
      </AzureAD>
    </>
  );
}
export default App;
